import React from 'react'
import {Link} from 'gatsby'

import Layout from '../../components/layout'
import ServicesMenu from './_menu.js'

const DesignServicesPage = () => (
  <Layout bgClass="services">
    <ServicesMenu pageName="design" />

    <div className="textPageDetails">
    <p>
      Have a project that needs art? Nakatomi has experience designing numerous gigposters, album covers, t-shirts, and comic books and can meet your needs or find a quality artist who will. <Link to="/contact">Contact us</Link> to get started.
    </p>
    </div>
  </Layout>
)

export default DesignServicesPage
